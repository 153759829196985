.og-card {
  display: block;
  margin-inline: 2rem;
  width: auto;
  border: 1px solid rgba(229, 229, 229, 1);
  text-decoration: none !important;
  margin-block: 2rem;
}

.og-card .og-img {
  width: 100%;
  aspect-ratio: 380/200;
}

.og-card .og-site {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.og-card .og-title {
  width: 100%;
}

.og-card .contents {
  padding: 2rem;
  border-top: 1px solid #e5e5e5;
}

.og-favicon {
  margin-right: .2rem;
  width: 1.8rem;
  height: 1.8rem;
}

.og-site-name {
  font-size: 1.2rem;
}

.og-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-block: 1rem;
}

.og-description {
  font-size: 1.2rem;
  color: rgba(153, 153, 153, 1);
}