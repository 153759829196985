.og-card {
  border: 1px solid #e5e5e5;
  width: auto;
  margin-block: 2rem;
  margin-inline: 2rem;
  display: block;
  text-decoration: none !important;
}

.og-card .og-img {
  aspect-ratio: 380 / 200;
  width: 100%;
}

.og-card .og-site {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.og-card .og-title {
  width: 100%;
}

.og-card .contents {
  border-top: 1px solid #e5e5e5;
  padding: 2rem;
}

.og-favicon {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: .2rem;
}

.og-site-name {
  font-size: 1.2rem;
}

.og-title {
  margin-block: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}

.og-description {
  color: #999;
  font-size: 1.2rem;
}
/*# sourceMappingURL=index.f699c19c.css.map */
